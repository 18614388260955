// let root = $(':root');

// // ------------------- FUNÇÃO DE COLAPSAR O MENU ----------------------
// let botoes = $('.botoes');
// let menu_lateral = $('#menu-lateral');
// let btn_colapsar = $('#colapsar-menu');
// let container_conteudo = $('.container-conteudo.col-8');
// let container_mensagem = $('.col-9.mensagem');
// let largura_menu = 225;

// function colapsar(e) {
//     event.preventDefault();
//     if (largura_menu > 0) {
//         largura_menu = 0;
//         menu_lateral.css('display', 'none');
//     } else {
//         largura_menu = 225;
//         menu_lateral.css('display', 'inline-block');
//     }
//     root.css('--largura-menu', `${largura_menu}px`);
// }


// //--------------- MENU INFERIOR--------------

// /*--------------Validador de URL------------*/
// let urlAtual = window.location.href;
// console.log(urlAtual);
// let qtdUrl = 0;
// let local = '.'
// for(let i = 0; i <= urlAtual.length; i++){
//     if(urlAtual.charAt(i) == '/'){
//         qtdUrl = qtdUrl + 1;
//     }
// }
// if(qtdUrl > 3 && (urlAtual.charAt(0) != 'f')){
//     local = '..'
// }

// let imgRotina = $('#imgRotina');
// let imgGestao = $('#imgGestao');
// let imgAnaliticos = $('#imgAnaliticos');
// let imgCanal = $('#imgCanal');
// let imgAjustes = $('#imgAjustes');
// let imgInteligencia = $('#imgInteligencia');
// /*-----------------------*/
// $(imgRotina).mouseenter(function () {
//     imgRotina.attr('src', `${local}/img/rotina_clinica-hover.png`);
// })
// $(imgRotina).mouseout(function () {
//     imgRotina.attr('src', `${local}/img/rotina_clinica.png`);
// })
// /*-----------------------*/
// $(imgGestao).mouseenter(function () {
//     imgGestao.attr('src', `${local}/img/gestao_simplificada-hover.png`);
// })
// $(imgGestao).mouseout(function () {
//     imgGestao.attr('src', `${local}/img/gestao_simplificada.png`);
// })
// /*-----------------------*/
// $(imgAnaliticos).mouseenter(function () {
//     imgAnaliticos.attr('src', `${local}/img/analiticos-hover.png`);
// })
// $(imgAnaliticos).mouseout(function () {
//     imgAnaliticos.attr('src', `${local}/img/analiticos.png`);
// })
// /*-----------------------*/
// $(imgAjustes).mouseenter(function () {
//     imgAjustes.attr('src', `${local}/img/ajustes-hover.png`);
// })
// $(imgAjustes).mouseout(function () {
//     imgAjustes.attr('src', `${local}/img/ajustes.png`);
// })
// /*-----------------------*/
// $(imgAjustes).mouseenter(function () {
//     imgAjustes.attr('src', `${local}/img/ajustes-hover.png`);
// })
// $(imgAjustes).mouseout(function () {
//     imgAjustes.attr('src', `${local}/img/ajustes.png`);
// })
// /*-----------------------*/
// $(imgCanal).mouseenter(function () {
//     imgCanal.attr('src', `${local}/img/canal_de_relacionamento-hover.png`);
// })
// $(imgCanal).mouseout(function () {
//     imgCanal.attr('src', `${local}/img/canal_de_relacionamento.png`);
// })
// /*-------------------------*/
// $(imgInteligencia).mouseenter(function () {
//     imgInteligencia.attr('src', `${local}/img/inteligencia_artificial-hover.png`);
// })
// $(imgInteligencia).mouseout(function () {
//     imgInteligencia.attr('src', `${local}/img/inteligencia_artificial.png`);
// })

// /**----------------WORKFLOW---------------- */
// let tarefas = $('.tarefas');
// for (let i = 0; i <= tarefas.length; i++) {
//     if (typeof tarefas[i] !== 'undefined') {
//         if (tarefas[i].innerHTML > 0) {
//             tarefas[i].classList.add('text-danger');
//         } else {
//             tarefas[i].classList.add('text-primary');
//         }
//     }
// }


// /**-------COMO ESTÁ SEU CONSULTÓRIO-----------*/
// let como_esta_consultorio = $('#workflow_consultorio');
// function esconder(e) {
//     event.preventDefault();
//     console.log(como_esta_consultorio);
// }

// /*------------------SUB-MENUS------------------*/
// let item = $('.item');

// let aberto = false;
// function exibir(e) {
//     event.preventDefault();
//     let sub_items = $(this).next()
//     if (aberto) {
//         sub_items.css('display', 'none');
//         aberto = false;
//     } else {
//         sub_items.css('display', 'inline-block');
//         aberto = true;
//     }
// }

// /*---------------------------------------------------- */
// /*-----------------TELA DE LOGIN--------------------- */
// /*---------------------------------------------------- */

// /*---------------Fechar pop-up------------------------- */
// let btn_fechar_pop = $("a.btn-fechar");
// let local_trabalho = $("div.container-local-trabalho");

// function fechar_pop(e) {
//     event.preventDefault();
//     local_trabalho.css('visibility', 'hidden')
// }

// /*---------------Menu Academico---------*/
// let btn_academico = $('#academico');
// let submenu = $('#submenu');
// let submenu_visivel = false;

// function menu_academico(e) {
//     event.preventDefault();
//     if (submenu_visivel == false) {
//         submenu.css('visibility', 'visible');
//         submenu_visivel = true;
//     } else {
//         submenu.css('visibility', 'hidden');
//         submenu_visivel = false;
//     }
// }

// /*--------------Sua Conta----------------*/
// let suaConta = $('#suaConta');
// let dadosLogin = $('#dadosLogin');
// let suaContaVisivel = false;

// function exibirSuaconta(e) {
//     event.preventDefault();
//     if(suaContaVisivel == false){
//         dadosLogin.css('visibility','visible');
//         suaContaVisivel = true;
//     } else {
//         dadosLogin.css('visibility','hidden');
//         suaContaVisivel = false;
//     }
// }

// //--------------------- FUNÇÕES---------------------
// // $(como_esta_consultorio).click(esconder);
// // $(btn_fechar_pop).click(fechar_pop);
// // $(suaConta).click(exibirSuaconta);
// // $(academico).click(menu_academico);
// // $(item).click(exibir);
// // $(btn_colapsar).click(colapsar);
// // $(cabecalho_menu).click(ativo);


